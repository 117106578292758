@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.switch-button {
		@apply flex
        w-full items-center justify-center;
	}
	.switch-button input {
		@apply hidden;
	}
	.switch-button label {
		@apply relative m-4
          block
          h-8
          w-20 
          cursor-pointer
          rounded-2xl
          after:absolute
          after:top-1
          after:left-1
          after:h-6
          after:w-6
          after:rounded-full
          after:bg-white
          after:duration-300
          active:after:w-3/5;
	}
	.switch-button h3 {
		@apply w-1/3
          cursor-pointer
          select-none
          text-xs
          duration-300
          hover:text-blue-500
          md:text-base;
	}
	.call-participant {
		@apply relative
      flex flex-col items-center justify-self-stretch
      overflow-hidden
      md:flex-row;
	}
	.call-participant.small {
		@apply absolute bottom-0 right-0 z-10 aspect-video rounded-lg xl:rounded-xl h-1/5;
	}
	.call-participant.large {
		@apply object-contain landscape:aspect-video w-full portrait:h-full;
	}
	.myVideo {
		@apply
		aspect-square xl:aspect-video h-32 md:h-36 xl:h-40 rounded-lg xl:rounded-xl bg-gray-600 cursor-move;
	}
	.myVideo:not(.react-draggable-dragging) {
		-webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
		transition: transform 0.5s ease-out;
	}
	.room-video{
		@apply aspect-video bg-red-300;
	}

	.Scenary {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding: 15px;
		gap: 20px;
		
	}
	
	/* Container of Screen and Dish */
	.Conference {
		display: flex;
		flex: 1;
		border-radius: 10px;
		gap: 15px;
		max-height: 100%;
		max-width: 100%;
		@apply flex-row portrait:flex-col;
	}
	
	/* Container of Cameras */
	.Dish {
		display: flex;
		align-content: center;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		flex: 1;
		border-radius: 10px;
		@apply overflow-hidden;
	}

	.Dish.expand{
		@apply flex-row flex-nowrap overflow-x-auto;
	}

	/* Camera */
	.Dish>div {
		position: relative;
		vertical-align: middle;
		align-self: center;
		border-radius: 10px;
		overflow: hidden;
		display: inline-block;
		cursor: pointer;
		box-shadow: var(--shadow-dark);
		animation: show 0.4s ease;
	}

	.Dish>div.hidden {
		display: none;
	}

	/* Video (check the nice property object-fit) */
	.Dish>div video {
		position: absolute;
		right: 0;
		object-fit: cover;
		bottom: 0;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		overflow: hidden;
		left: 0;
		top: 0;
		background-size: cover;
		background: #fff;
		overflow: hidden;
		-webkit-transition: margin-top 1s ease-in-out;
		-moz-transition: margin-top 1s ease-in-out;
		-o-transition: margin-top 1s ease-in-out;
		transition: margin-top 1s ease-in-out;
	}

	.Dish>div video.self {
		@apply -scale-x-100;
	}
	
	/* Animation of Loading Video */
	.Dish>div video.loading {
		margin-top: 100%;
	}

	.Dish div:after {
		z-index: 10;
		font-family: Arial, Helvetica, sans-serif;
		position: absolute;
		bottom: 5px;
		left: 5px;
		font-weight: 100;
		content: attr(name);
		display: block;
		@apply min-w-[3em] text-xs xl:text-sm text-gray-300 text-center bg-black/30 rounded-full px-1 py-0.5 sm:px-2 sm:py-1;
	}
	
	
	/* Screen */
	.Screen {
		position: absolute;
		z-index: 20;
		top:0;
		bottom: 0;
		left: 0;
		right: 0;
		border-radius: 10px;
		overflow: hidden;
		height: fit-content;
		display: flex;
		align-self: center;
		width: 100%;
		height: 100%;
		@apply rounded-xl aspect-square sm:aspect-video bg-black/30;
	}

	.Screen:after {
		z-index: 10;
		font-size: 15px;
		font-family: Arial, Helvetica, sans-serif;
		position: absolute;
		bottom: 5px;
		left: 5px;
		font-weight: 100;
		content: attr(name);
		display: block;
		@apply min-w-[3em] text-gray-300 text-center bg-black/30 rounded-full px-2 py-1;
	}

	/* Video (check the nice property object-fit) */
	.Screen>video {
		@apply self-center sm:aspect-video w-full h-full bg-black object-contain;
	}

	.Screen>button {
		@apply absolute top-2 right-2 text-xl text-white;
	}

	/* Animation of Cameras */
	@keyframes show {
		0% {
			opacity: 0;
			transform: scale(0.4) translateY(20px);
		}
	
		100% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}
	}

	.self-video{
		@apply w-full h-full -scale-x-100 rounded-lg xl:rounded-xl overflow-hidden object-cover;
	}

	.virtual-blur-default{
		@apply flex w-12 h-12 border bg-slate-300 rounded-md items-center justify-center p-2;
	}

	.selected-effect{
		@apply border-2 border-blue-600;
	}

	.virtual-image{
		@apply cursor-pointer w-28 aspect-video rounded-md object-cover;
	}

	/* .virtual-image .selected{
		@apply border-2 border-blue-600;
	} */

	.self-video .blur {
		-webkit-filter: blur(5px);
		filter: blur(5px);
	}

	.blur {
		-webkit-filter: blur(8px);
		filter: blur(8px);
	}

	.mirror {
		@apply -scale-x-100;
	}

}

@layer base {
	html,
	body,
	#root {
		@apply m-0 h-full;
	}
	body {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
			'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: transparent;
		touch-action: pan-y;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input:focus,
	textarea:focus,
	select:focus {
		outline: none;
	}
}
